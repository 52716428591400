import React from 'react';
/* import { Icon } from 'design-react-kit/dist/design-react-kit'; */
//eslint-disable-next-line import/no-unresolved
import Logo from '@package/components/Layout/logo_footer.png?width=210';
/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */

const LogoFooter = () => (
  <img
    src={Logo}
    width="210"
    height="55"
    alt="Logo Servizio Sanitario Regionale Emilia Romagna"
  />
);

export default LogoFooter;
