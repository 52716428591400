/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import applyItaliaConfig from '@italia/config/italiaConfig';
import Head from '@package/components/Head.jsx';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    matomoSiteId: 'gV3jnVN3AE',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: Head,
      },
    ],
  };

  config.settings.italiaThemeViewsConfig.imagePosition = 'afterHeader'; // possible values: afterHeader, documentBody
  config.settings.siteProperties = {
    ...config.settings.siteProperties,
    siteTitle: "Arcispedale Sant'Anna",
    siteSubtitle: '',
    parentSiteTitle: 'Azienda Ospedaliero - Universitaria di Ferrara',
    parentSiteURL: 'https://www.ospfe.it/',
    subsiteParentSiteTitle:
      "Azienda Ospedaliero Universitaria di Ferrara - Arcispedale Sant'Anna",
    footerInfos: '',
    // arLoginUrl: 'http://intranet.azospfe.it/',
    arLoginUrl: 'https://www.ospfe.it/area-dipendenti',
    arLogoutUrl: '/logout',
    // smallFooterLinks: {
    //   default: [
    //     {
    //       title: 'Privacy policy',
    //       url:
    //         'https://at.ospfe.it/disposizioni-generali/atti-generali/atti-amministrativi-generali/privacy',
    //     },
    //     { title: 'Mappa del sito', url: '/sitemap' },
    //     { title: 'Credits', url: 'https://www.redturtle.it/' },
    //   ],
    //   it: [
    //     {
    //       title: 'Privacy policy',
    //       url:
    //         'https://at.ospfe.it/disposizioni-generali/atti-generali/atti-amministrativi-generali/privacy',
    //     },
    //     { title: 'Mappa del sito', url: '/it/sitemap' },
    //     { title: 'Credits', url: 'https://www.redturtle.it/' },
    //   ],
    //   en: [
    //     {
    //       title: 'Privacy policy',
    //       url:
    //         'https://at.ospfe.it/disposizioni-generali/atti-generali/atti-amministrativi-generali/privacy',
    //     },
    //     { title: 'Sitemap', url: '/en/sitemap' },
    //     { title: 'Credits', url: 'https://www.redturtle.it/' },
    //   ],
    // },
  };

  config.settings.siteProperties.markSpecialLinks = false;

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.it.description =
    "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookie di profilazione dell'utente per fini statistici. Per i cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Impostazioni'. Per saperne di più, su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='https://at.ospfe.it/disposizioni-generali/atti-generali/atti-amministrativi-generali/privacy'>Cookie Policy</a>.";

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2023-01-02T18:00:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
              fr: {
                title: 'Matomo',
                description:
                  "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
              },
            },
          },
          /*...config.settings[
            'volto-gdpr-privacy'
          ].defaultPanelConfig.technical.choices.filter(
            (f) => f.config_key !== 'GANALYTICS',
          ),*/
        ],
      },
    },
  };
  return config;
}
