/**
 * FooterNavigation components.
 * @module components/ItaliaTheme/Footer/FooterNavigation
 */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { defineMessages, useIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { getBaseUrl } from '@plone/volto/helpers';
import { getNavigation } from '@plone/volto/actions';
import { UniversalLink } from '@plone/volto/components';
import {
  Row,
  Col,
  LinkList,
  LinkListItem,
} from 'design-react-kit/dist/design-react-kit';

import { SectionIcon } from '@italia/components/ItaliaTheme';

const messages = defineMessages({
  goToPage: {
    id: 'Vai alla pagina',
    defaultMessage: 'Vai alla pagina',
  },
});

const FooterNavigation = () => {
  return <></>;
};

export default FooterNavigation;
